// main
// ======================================
.drawer-main{
  position: fixed;
  top:0;
  overflow: hidden;
  width:@drawer-width-phone;
  height:100%;
  .drawer-left & {
    left : (-@drawer-width-phone);
    transition:left @drawer-duration @drawer-ease ;
  }  
  .drawer-left.drawer-open & {
    left: 0;
  }
  .drawer-right & {
    right: (-@drawer-width-phone);
    transition:right @drawer-duration @drawer-ease;
  }
  .drawer-right.drawer-open & {
    right:0;
  }
}

// overlay
// ======================================
.drawer-overlay{
  position: relative;
  .drawer-left & {
    left:0;
    transition:left @drawer-duration @drawer-ease;
  }
  .drawer-left.drawer-open & {
    left:@drawer-width-phone;
  }
  .drawer-right & {
    right:0;
    transition:right @drawer-duration @drawer-ease;
  }
  .drawer-right.drawer-open & {
    right:@drawer-width-phone;
  }
}

// upper
// ======================================
.drawer-overlay-upper{
  position:fixed;
  z-index:@drawer-upper-zIndex;
  top:0;
  height:100%;
  display:none;
  background-color: rgb(0,0,0,.5);
  background-color: rgba(0,0,0,.5);
  .drawer-left.drawer-open & {
    right:0;
  }
  .drawer-right.drawer-open & {
    left:0;
  }
}