// phone
@media (min-width: (768px - 1)) {
  .drawer-responsive.drawer-left,
  .drawer-responsive.drawer-right{
    .drawer-toggle{
      display: none;
      visibility: hidden;
    }  
  }
  .drawer-responsive.drawer-left {
    .drawer-main{
      left:0;
    }
  }
  .drawer-responsive.drawer-right {
    .drawer-main{
      right:0;
    }
  }
}

// tablet
@media (min-width: 768px) {
  .drawer-responsive{
    background-color:@drawer-nav-bg-color;
  }
  .drawer-responsive.drawer-left,
  .drawer-responsive.drawer-right{
    .drawer-main{
      position: absolute;
      width:@drawer-width-tablet !important;
      height:auto !important;
      overflow:visible !important;
    }
  }
  .drawer-responsive.drawer-left{
    .drawer-main{
      float: left;
    }
    .drawer-overlay{
      margin-left:@drawer-width-tablet;
    }
  }
  .drawer-responsive.drawer-right{
    .drawer-main{
      float: right;
    }
    .drawer-overlay{
      margin-right:@drawer-width-tablet;
    }
  }
}

// desktop
@media (min-width: 1200px) {
  .drawer-responsive.drawer-left,
  .drawer-responsive.drawer-right{
    .drawer-main{
      width:@drawer-width-desktop !important;
    }
  }
  .drawer-responsive.drawer-left{
    .drawer-overlay{
      margin-left:@drawer-width-desktop;
    }
  }
  .drawer-responsive.drawer-right{
    .drawer-overlay{
      margin-right:@drawer-width-desktop;
    }
  }
}