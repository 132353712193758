.drawer-hamberger { 
  position:fixed;  
  padding:20px;
  width:70px;
  height:70px;
  background-color: transparent; 
  border: 0;
  z-index: @drawer-toggle-zIndex;
  display: block;
  span {
   margin-top:10px;
  }
  span, 
  span:before, 
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 2px;
    width: 30px;
    background-color: @drawer-hamberger-bg-color;
    position: absolute;
    display: block;
    content: '';
    transition: all @drawer-duration @drawer-ease;
  }
  span:before {
    top: -10px; 
  }
  span:after {
    bottom: -10px;
  }
  span {
    .drawer-open &{
      background-color: transparent;
    }
  }
  span:before, 
  span:after {
    .drawer-open &{
      top: 0;
    }
  }
  span:before {
    .drawer-open &{
      transform: rotate(45deg);
    }
  }
  span:after {
    .drawer-open &{
      transform: rotate(-45deg);
    }
  }
  &:hover{
    cursor: pointer;
  }
  
  .drawer-left &:extend(.drawer-left .drawer-overlay) {}
  .drawer-left.drawer-open &:extend(.drawer-left.drawer-open .drawer-overlay) {}
    
  .drawer-right &:extend(.drawer-right .drawer-overlay) {}
  .drawer-right.drawer-open &:extend(.drawer-right.drawer-open .drawer-overlay) {}
  
}
